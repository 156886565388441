import React from 'react'
import CategoryTable from '../AdminComponents/CategoryTable'
function Categories() {
  return (
    <div>
      <CategoryTable/>
    </div>
  )
}

export default Categories
import React from 'react'
import Coupon from '../coupon/Coupon'
function Couponpage() {
  return (
    <div>

<Coupon/>



    </div>
  )
}

export default Couponpage
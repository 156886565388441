import React from 'react'

function ConfirmationDialogue() {
  return (
    <div>
        
        
        </div>
  )
}

export default ConfirmationDialogue